<template>
  <div class="container d-flex align-items-center justify-content-center flex-column">
    <img src="~/mlearn/icons/loading.svg" alt="Loading..." width="80px" />
    Đang đăng nhập ...
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { loginGoogle } from '~/common/api/auth'

export default {
  layout: 'event',
  middleware: 'unauthenticated',
  created () {
    this.loginGoogle()
  },
  methods: {
    loginGoogle () {
      const str = this.$route.hash
      const regex = /id_token=(.*?)&/gm
      let m
      let idToken = ''

      while ((m = regex.exec(str)) !== null) {
        idToken = m[1]
      }
      if (idToken) {
        const params = {
          accessToken: idToken
        }
        loginGoogle(params)
          .then((response) => {
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.commit('SET_TOKEN', response.data.accessToken)
            this.$store.commit('SET_USER', {
              email: response.data.email,
              fullName: response.data.fullName,
              userName: response.data.userName,
              avatar: response.data.avatar,
              uuid: response.data.uuid
            })
            const redirect = Cookies.get('redirect')
            Cookies.remove('redirect')
            const url = process.env.NUXT_ENV_BASE_URL + redirect
            window.location.href = url
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

<style scoped>
.container {
  min-height: 100vh;
}
</style>
